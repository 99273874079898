<template>
  <div class="offer-not-found tw-bg-white tw-flex tw-w-full">
    <div class="offer-not-found__wrapper tw-text-center tw-m-auto md:tw-w-1/2 xl:tw-w-1/3 tw-flex tw-flex-col tw-py-12 tw-px-4">
      <picture class="tw-mb-6">
        <img
          src="@/assets/img/icons/rejected.svg"
          width="70"
          height="70"
          alt=""
          data-test="icon"
        >
      </picture>

      <h1
        class="tw-font-medium tw-text-2xl tw-mb-6"
        v-text="$t('offers.titles.not_found')"
        data-test="title"
      />
      <p
        class="tw-text-sm tw-mb-6"
        v-text="$t('offers.paragraphs.not_found')"
        data-test="content"
      />
      <ui-button
        :to="{
          name: 'Offers'
        }"
        class="tw-text-white tw-mx-auto tw-px-8"
        variant="primary"
        data-test="view-offers"
      >
        {{ $t('offers.buttons.view_offers') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    setup () {
    }
  })
</script>

<style lang="scss" scoped>
.offer-not-found {
  position: relative;
}
@media (min-width: 770px) {
  .offer-not-found {
    padding-bottom: 250px;
  }
  .offer-not-found::after {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 32px;
    width: 70%;
    min-height: 170px;
    content: '';
    background-size: cover;
    background-image: url('~@/assets/img/illustrations/shipment_confirmation.svg');
    background-position: center bottom;
    opacity: 0.8;
  }
}
</style>
